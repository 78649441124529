import React from "react"
import PropTypes from "prop-types"
import Layout from "../templates/Page"
import Header from "../components/ContentBuilder/Header"

BoardDetail.propTypes = {}

function BoardDetail(props) {
  return (
    <Layout hideHeader>
      <Header isLogin />
      <div className="container">
        <div className="row no-gutters justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8">
            <div className="account-avt-wrap">
              <div className="account-avt">
                <div className="account-avt-inner">
                  <span>CA</span>
                  <img src="/img/christop-3.png"></img>
                </div>
              </div>
              <p className="account-avt-name">Thien Nguyen Huu</p>
            </div>
          </div>
        </div>
        <div className="mt-30 mb-30 border-bottom-7px">
          <div className="row align-items-center">
            <div className="col-md">
              <h1 className="product-name-title">Test Board</h1>
            </div>
          </div>
        </div>
        <div className="row board-wrap">
          <div className="col-12 col-md-6 col-lg-3">
            <a href="#" className="grid-item">
              <img src="/img/related-articles-01.jpg" alt="" />
              <div className="grid-caption">
                <button type="button" className="btn-like">
                  <span className="sr-only">Like</span>
                </button>
                <h4>Tile Title</h4>
              </div>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <a href="#" className="grid-item">
              <img src="/img/related-articles-02.jpg" alt="" />
              <div className="grid-caption">
                <button type="button" className="btn-like">
                  <span className="sr-only">Like</span>
                </button>
                <h4>Tile Title</h4>
              </div>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <a href="#" className="grid-item">
              <img src="/img/related-articles-03.jpg" alt="" />
              <div className="grid-caption">
                <button type="button" className="btn-like">
                  <span className="sr-only">Like</span>
                </button>
                <h4>Tile Title</h4>
              </div>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <a href="#" className="grid-item">
              <img src="/img/related-articles-04.jpg" alt="" />
              <div className="grid-caption">
                <button type="button" className="btn-like">
                  <span className="sr-only">Like</span>
                </button>
                <h4>Tile Title</h4>
              </div>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <a href="#" className="grid-item">
              <img src="/img/related-articles-04.jpg" alt="" />
              <div className="grid-caption">
                <button type="button" className="btn-like">
                  <span className="sr-only">Like</span>
                </button>
                <h4>Tile Title</h4>
              </div>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <a href="#" className="grid-item">
              <img src="/img/related-articles-03.jpg" alt="" />
              <div className="grid-caption">
                <button type="button" className="btn-like">
                  <span className="sr-only">Like</span>
                </button>
                <h4>Tile Title</h4>
              </div>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <a href="#" className="grid-item text-black">
              <img src="/img/related-articles-02.jpg" alt="" />
              <div className="grid-caption">
                <button type="button" className="btn-like">
                  <span className="sr-only">Like</span>
                </button>
                <h4>Tile Title</h4>
              </div>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BoardDetail
